import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formData : any = [];
  constructor(
    private http : HttpClient,
    // private toastr: ToastrService,
    private router : Router
  ) { }

  ngOnInit(): void {
  }
  
  login() {
      var dataset =        {
        "action": "ADMIN_LOGIN",
        "body": {
            "email": this.formData.email,
            "password": Md5.hashStr(this.formData.password)
        }
    }

    this.http.post(environment.baseURL + '/auth.php',JSON.stringify(dataset)).subscribe(res => {
      console.log(res);
      if(res['result'].length == 0){
        // this.toastr.error('ข้อมูลไม่ถูกต้อง', 'ไม่สำเร็จ');
      }else{
        // this.toastr.success('กำลังเปลี่ยนเส้นทาง', 'สำเร็จ');
        localStorage.setItem('uuid',res['result'].uuid);
        this.router.navigateByUrl('/dashboard');
      }

    },err => {
      console.log(err);
    });
  }

}
