import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

    listData: any = [];
    listCustomer: any = [];
    listAdmin: any = [];
    formData : any = [];
    editSelect = 0;
    selectYear = 2567;
    constructor(
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.getData();
        this.getCustomer();
        this.getAdmin();
    }

    getData() {
        this.editSelect = 0;
        var dataset = {
            "action": "GET_DATA",
            "body": {
                "year": this.selectYear
            }
        }

        this.http.post(environment.baseURL + '/project.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listData = Object.keys(res['result']).map(key =>  res['result'][key]);
        }, err => {
            console.log(err);
        });
    }

    getCustomer(){
        var dataset =  {
          "action": "GET_DATA"
        }
    
        this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.listCustomer = res['result'];
        },err => {
          console.log(err);
        });
      }

    getAdmin(){
        var dataset =  {
            "action": "GET_DATA"
          }
      
          this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listAdmin = res['result'];
          },err => {
            console.log(err);
          });
      }

    insertData() {
        var dataset = {
            "action": "INNERT_DATA",
            "body": {
                "name": this.formData.name,
                "start_time": this.formData.start_time,
                "end_time": this.formData.end_time,
                "price_sum": this.formData.price_sum,
                "status": this.formData.status,
                "type": this.formData.type,
                "year": this.formData.year,
                "customer_id": this.formData.customer_id,
                "admin_id": this.formData.admin_id,
                "detail":this.formData.detail
            },
            "condition": {
                "price_pay":  this.formData.price_pay
            }
        }

        this.http.post(environment.baseURL + '/project.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            $('#inSertProject').modal('hide');
            this.listData = [];
            this.getData();
        }, err => {
            console.log(err);
        });
    }

    removeData(id){
        var dataset =    {
            "action": "DELETE_DATA",
            "condition": {
                "record_id": id
            }
        }

        this.http.post(environment.baseURL + '/project.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            $('#inSertProject').modal('hide');
            this.listData = [];
            this.getData();
        }, err => {
            console.log(err);
        });
    }

    updateData(){
        var dataset = {
            "action": "UPDATE_DATA",
            "body": {
                "name": this.formData.name,
                "start_time": this.formData.start_time,
                "end_time": this.formData.end_time,
                "price_sum": this.formData.price_sum,
                "status": this.formData.status,
                "type": this.formData.type,
                "year": this.formData.year,
                "customer_id": this.formData.customer_id,
                "admin_id": this.formData.admin_id,
                "detail":this.formData.detail
            },
            "condition": {
                "record_id": this.formData.id,
                "price_pay":  this.formData.price_pay
            }
        }

        this.http.post(environment.baseURL + '/project.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            $('#inSertProject').modal('hide');
            this.listData = [];
            this.getData();
        }, err => {
            console.log(err);
        });
    }

}
