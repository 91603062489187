import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    typeChart: any;
    dataChart: any;
    optionsChart: any;

    typeChartst: any;
    dataChartst: any;
    optionsChartst: any;

    mainData: any = [];
    listmetting: any = [];
    listProject: any = [];
    myApp: any = [];
    websiteGroup = {
        "total": 0,
        "running": 0,
        "success": 0
    };
    appGroup = {
        "total": 0,
        "running": 0,
        "success": 0
    };
    supportGroup = {
        "total": 0,
        "running": 0,
        "success": 0
    };
    constructor(
        private http: HttpClient
    ) {
        this.getData();
        this.getDataMetting();
        this.getDataProject();
        this.getCenter();
    }

    ngOnInit() {
        this.typeChart = 'line';   ////// สามารถกำหนดเป็น 'line','bar','radar','pie','doughnut','polarArea','bubble','scatter'
        this.dataChart = {
            labels: ["January", "February", "March", "April", "May"],
            datasets: [
                {
                    label: "My Stats Chart",
                    data: [10, 30, 50, 30, 40],
                }
            ]
        };
        this.optionsChart = {
            responsive: true,
            maintainAspectRatio: false
        };

        this.statusWork();
    }

    getCenter(){
        var dataset = {
            "action": "GET_DATA"
        }

        this.http.post(environment.baseURL + '/app.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.myApp = Object.keys(res['result']).map(key => res['result'][key]);
        }, err => {
            console.log(err);
        });
    }

    statusWork() {
        this.typeChartst = 'doughnut';   ////// สามารถกำหนดเป็น 'line','bar','radar','pie','doughnut','polarArea','bubble','scatter'
        this.dataChartst = {
            labels: ["รอข้อมูล", "ดำเนินการ", "สำเร็จ"],
            datasets: [
                {
                    label: "My Stats Chart",
                    data: [10, 30, 50],
                    backgroundColor: ['#1abc9c', '#3498db', '#9b59b6']
                }
            ]
        };
        this.optionsChartst = {
            responsive: true,
            maintainAspectRatio: false,
            pieceLabel: {
                render: 'percentage',  // สามารถเปลี่ยนการตั้งค่าตามต้องการได้ 'value','label','percentage'
                fontSize: 10,
                fontStyle: 'bold',
                fontColor: '#FFF'
            },
        };
    }

    getData() {
        var dataset = {
            "action": "GET_DATA"
        }

        this.http.post(environment.baseURL + '/dashboard.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.mainData = res['result'];
        }, err => {
            console.log(err);
        });
    }

    getDataMetting() {
        var dataset = {
            "action": "GET_DATABYSTATUS"
        }

        this.http.post(environment.baseURL + '/metting.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listmetting = res['result'];
        }, err => {
            console.log(err);
        });
    }


    appoveMetting(id) {
        var dataset = {
            "action": "UPDATE_DATA",
            "body": {
                "status": 2
            },
            "condition": {
                "record_id": id
            }
        }

        this.http.post(environment.baseURL + '/metting.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listmetting = [];
            this.getDataMetting();
        }, err => {
            console.log(err);
        });
    }


    getDataProject() {
        var dataset = {
            "action": "GET_DATA",
            "body": {
                "year": 2564
            }
        }

        this.http.post(environment.baseURL + '/project.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listProject = Object.keys(res['result']).map(key => res['result'][key]);

            for (let i = 0; i < this.listProject.length; i++) {
                if (this.listProject[i].type == 1) {
                    this.websiteGroup.total += 1;
                    if (this.listProject[i].status < 4) {
                        this.websiteGroup.running += 1;
                    } else {
                        this.websiteGroup.success += 1;
                    }
                } else if (this.listProject[i].type == 2) {
                    this.appGroup.total += 1;
                    if (this.listProject[i].status < 4) {
                        this.appGroup.running += 1;
                    } else {
                        this.appGroup.success += 1;
                    }
                } else if (this.listProject[i].type == 3) {
                    this.supportGroup.total += 1;
                    if (this.listProject[i].status < 4) {
                        this.supportGroup.running += 1;
                    } else {
                        this.supportGroup.success += 1;
                    }
                }
            }

            console.log(this.websiteGroup);

        }, err => {
            console.log(err);
        });
    }
}