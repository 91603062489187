<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext" ><b>โปรเจ็คทั้งหมด</b></li>
            <li>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" style="border-radius: .25rem 0 0 .25rem;height: 45px;">เลือกปีเเสดงผล</div>
                    </div>
                    <select [(ngModel)]="selectYear" (change)="getData()" class="form-control" id="exampleFormControlSelect1" style="text-align: center;">
                        <option value="2563" >2563</option>
                        <option value="2564" >2564</option>
                        <option value="2565" >2565</option>
                        <option value="2566" >2566</option>
                        <option value="2567" >2567</option>
                        <option value="2568" >2568</option>
                    </select>
                </div>
            </li>
            <li>
                <button (click)="formData = [];" data-bs-toggle="modal" data-bs-target="#inSertProject" class="btn btn-dark text-white" ><i class="bi bi-bag-plus-fill"></i> เพิ่มโปรเจ็คใหม่</button>
            </li>
            </ul>
        </div>
        <div class="card-body" >
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ชื่องาน</th>
                    <th scope="col" style="width:100px;" >เริ่มงาน</th>
                    <th scope="col" style="width:100px;" >ส่งมอบงาน</th>
                    <th scope="col" style="width:80px;" >ระยะเวลา</th>
                    <th scope="col" style="text-align: center;width:80px;" >ราคารวม</th>
                    <th scope="col" style="text-align: center;background-color: #27ae60;width:80px;" >ชำระแล้ว</th>
                    <th scope="col" style="text-align: center;background-color: #eb3b5a;width:80px;" >ค้างชำระ</th>
                    <th scope="col" style="text-align: center;background-color: #d1d8e0;width:80px;" >สถานะ</th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listData;let i = index;" >
                    <td style="width: 40px;" >{{i + 1}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.start_time}}</td>
                    <td>{{item.end_time}}</td>
                    <td>{{item.maxtime}} วัน</td>
                    <td style="text-align: right;" >{{item.price_sum | number : '0.2-2'}}</td>
                    <td style="text-align: right;background-color: #27ae5f34;" >{{item.price_pay | number : '0.2-2'}}</td>
                    <td style="text-align: right;" [ngClass]="{'bg-red2' : item.price_sum - item.price_pay != 0}" >{{(item.price_sum - item.price_pay | number : '0.2-2')}}</td>
                    <td style="text-align: center;" [ngClass]="{'bg-blue' : item.status == 2,'bg-orange':item.status == 3,'bg-green':item.status == 4,'bg-red2':item.status == 5}" >
                        <span *ngIf="item.status == 1" style="color: #000;" >รอข้อมูล</span>
                        <span *ngIf="item.status == 2" style="color: #fff;" >ทำงาน</span>
                        <span *ngIf="item.status == 3" style="color: #fff;" >ตรวจสอบ</span>
                        <span *ngIf="item.status == 4" style="color: #fff;" >อนุมัติ</span>
                        <span *ngIf="item.status == 5" style="color: #fff;" >ยกเลิก</span>
                    </td>
                    <td (click)="formData = item;editSelect = 1;" data-bs-toggle="modal" data-bs-target="#inSertProject" style="text-align: center;">
                        <i class="bi bi-pencil"></i>
                    </td>
                    <td (click)="removeData(item.id)" style="text-align: center;">
                        <i style="color: #eb3b5a;" class="bi bi-trash"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="card-footer">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-end" style="margin: 0px !important;">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1">ก่อนหน้า</a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">ถัดไป</a>
                      </li>
                    </ul>
                  </nav>
            </div>
    </div>
  
  <!-- Modal -->
  <div class="modal fade" id="inSertProject" tabindex="-1" role="dialog" aria-labelledby="inSertProjectTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-secondary">
          <h5 class="modal-title text-white" id="exampleModalLongTitle">เพิ่มข้อมูลใหม่</h5>
        </div>
        <div class="modal-body">
            <div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-3">
                          <label class="form-label">ชื่อโปรเจ็ค</label>
                          <input [(ngModel)]="formData.name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                          <label class="form-label">ประเภทงาน</label>
                          <select [(ngModel)]="formData.type" class="custom-select form-control">
                            <option selected>เลือก...</option>
                            <option value="1">Website</option>
                            <option value="2">Application</option>
                            <option value="3">Support</option>
                            <option value="4">Design</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                          <label class="form-label">สถานะ</label>
                          <select [(ngModel)]="formData.status" class="custom-select form-control">
                            <option selected>เลือก...</option>
                            <option value="1">รอข้อมูล</option>
                            <option value="2">ทำงาน</option>
                            <option value="3">ตรวจสอบ</option>
                            <option value="4">สำเร็จ</option>
                            <option value="5">ยกเลิก</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">วันเริ่มทำงาน</label>
                            <input [(ngModel)]="formData.start_time" type="date" class="form-control">
                          </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">วันส่งมอบ</label>
                            <input [(ngModel)]="formData.end_time" type="date" class="form-control">
                          </div>
                    </div>
                    <div class="col-2">
                        <div class="mb-3">
                            <label class="form-label">ค่าบริการ</label>
                            <input [(ngModel)]="formData.price_sum" type="number" class="form-control">
                          </div>
                    </div>
                    <div class="col-2">
                        <div class="mb-3">
                            <label class="form-label">ชำระแล้ว</label>
                            <input [(ngModel)]="formData.price_pay" type="number" class="form-control">
                          </div>
                    </div>
                    <div class="col-2">
                        <div class="mb-3">
                            <label class="form-label">ปี</label>
                            <select [(ngModel)]="formData.year" class="form-control"  style="text-align: center;">
                              <option value="2563" >2563</option>
                              <option value="2564" >2564</option>
                              <option value="2565" >2565</option>
                              <option value="2566" >2566</option>
                              <option value="2567" >2567</option>
                          </select>
                          </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">ลูกค้า</label>
                            <select [(ngModel)]="formData.customer_id" class="custom-select form-control">
                                <option selected>Choose...</option>
                                <option *ngFor="let item of listCustomer;" value="{{item.id}}">{{item.name}}</option>
                              </select>
                          </div>
                    </div>
                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">ผู้รับผิดชอบ</label>
                            <select [(ngModel)]="formData.admin_id" class="custom-select form-control">
                                <option selected>Choose...</option>
                                <option *ngFor="let item of listAdmin;" value="{{item.id}}">{{item.name}}</option>
                              </select>
                          </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">รายละเอียดงาน</label>
                            <textarea [(ngModel)]="formData.detail" class="form-control" rows="5" ></textarea>
                          </div>
                    </div>
                </div>
            </div >

            <!-- add one close -->
            <div style="text-align: right;" >
                <button type="button" style="width:100px;margin-right: 10px;height: 45px;" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times"></i> ปิด</button>
                <button *ngIf="editSelect == 0" (click)="insertData()" type="button" style="width:180px;height: 45px;"  class="btn btn-success" data-bs-dismiss="modal"><i class="fas fa-plus"></i> เพิ่มรายการใหม่</button>
                <button *ngIf="editSelect == 1" (click)="updateData()" type="button" style="width:180px;height: 45px;background-color: #0984e3;color: #fff;"  class="btn" data-bs-dismiss="modal"><i class="fas fa-check"></i> อัพเดทข้อมูล</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</app-maincontent>