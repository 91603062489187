import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { ProjectComponent } from './project/project.component';
import { AdminComponent } from './admin/admin.component';
import { ReportComponent } from './report/report.component';
import { LoginComponent } from './login/login.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { ChartModule } from 'angular2-chartjs';
// import 'chart.piecelabel.js';
import { DomainComponent } from './domain/domain.component';
// import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuaranteeComponent } from './guarantee/guarantee.component';
import { MettingComponent } from './metting/metting.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceprintComponent } from './invoiceprint/invoiceprint.component';
import { QuotationComponent } from './quotation/quotation.component';
import { QuotationprintComponent } from './quotationprint/quotationprint.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SettingComponent } from './setting/setting.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CustomerComponent,
    ProjectComponent,
    AdminComponent,
    ReportComponent,
    LoginComponent,
    MaincontentComponent,
    DomainComponent,
    GuaranteeComponent,
    MettingComponent,
    InvoiceComponent,
    InvoiceprintComponent,
    QuotationComponent,
    QuotationprintComponent,
    ExpensesComponent,
    SettingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // ChartModule,
    BrowserAnimationsModule, // required animations module
    // ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
