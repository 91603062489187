<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext" >สมาชิก/ลูกค้า</li>
                <li>
                    <input type="text" placeholder="ค้นหาข้อมูล" class="form-control">
                </li>
                <li>
                    <button class="btn btn-dark text-white"><i class="bi bi-file-plus"></i> เพิ่มสมาชิก</button>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสลูกค้า</th>
                        <th scope="col">ชื่อลูกค้า</th>
                        <th scope="col">ชื่อบริษัท</th>
                        <th scope="col" style="width: 300px;">ที่อยู่</th>
                        <th scope="col" style="width: 150px;">เลขที่บัญชีผู้เสียภาษี</th>
                        <th scope="col" style="width: 150px;">หมายเลขโทรศัพท์</th>
                        <th scope="col" style="text-align: center;width:40px;"></th>
                        <th scope="col" style="text-align: center;width:40px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listData;let i = index;">
                        <td style="width: 40px;">{{i + 1}}</td>
                        <td style="width: 100px;">{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td style="text-align: center;">{{item.company}}</td>
                        <td style="text-align: center;">{{item.address}}</td>
                        <td style="text-align: center;">{{item.tax}}</td>
                        <td style="text-align: center;">{{item.phone}}</td>
                        <td (click)="formData = item;editSelect = 1;" onclick="$('#inSertDomain').modal('show')" style="text-align: center;">
                            <i class="bi bi-pencil"></i>
                        </td>
                        <td (click)="removeData(item.id)" style="text-align: center;">
                            <i style="color: #eb3b5a;" class="bi bi-trash"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end" style="margin: 0px !important;">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">ก่อนหน้า</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#">ถัดไป</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="inSertDomain" tabindex="-1" role="dialog" aria-labelledby="inSertDomainTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header bg-secondary">
                    <h5 class="modal-title text-white" id="exampleModalLongTitle">เพิ่มข้อมูลโดเมน</h5>
                </div>
                <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label class="form-label">ชื่อลูกค้า</label>
                                    <input [(ngModel)]="formData.name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="mb-3">
                                    <label class="form-label">รหัสลูกค้า</label>
                                    <input [(ngModel)]="formData.code" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="mb-3">
                                    <label class="form-label">หมายเลขโทรศัพท์</label>
                                    <input [(ngModel)]="formData.phone" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="mb-3">
                                    <label class="form-label">อีเมล</label>
                                    <input [(ngModel)]="formData.email" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="mb-3">
                                    <label class="form-label">บริษัท</label>
                                    <input [(ngModel)]="formData.company" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="mb-3">
                                    <label class="form-label">เลขที่บัญชีผู้เสียภาษี</label>
                                    <input [(ngModel)]="formData.tax" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">ที่อยู่</label>
                                    <textarea [(ngModel)]="formData.address" class="form-control"  ></textarea>
                                </div>
                            </div>
                    </div>

                    <!-- add one close -->
                    <div style="text-align: right;">
                        <button onclick="$('#inSertDomain').modal('hide')" type="button"
                            style="width:100px;margin-right: 10px;height: 45px;" class="btn btn-secondary"
                            data-bs-dismiss="modal"><i class="fas fa-times"></i> ปิด</button>
                        <button *ngIf="editSelect == 0" (click)="insertData()" type="button"
                            style="width:180px;height: 45px;" class="btn btn-success"><i class="fas fa-plus"></i>
                            เพิ่มรายการใหม่</button>
                        <button *ngIf="editSelect == 1" (click)="updateData()" type="button"
                            style="width:180px;height: 45px;background-color: #0984e3;color: #fff;" class="btn"><i
                                class="fas fa-check"></i> อัพเดทข้อมูล</button>
                    </div>
                </div> <!-- body -->
            </div> <!-- modal -->
        </div> <!-- c -->
    </div> <!--  -->
</app-maincontent>