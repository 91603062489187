<div class="print" >
    <div class="row">
        <div class="col-7">
            <h1 class="textcenter" style="color: red;" ><b>INVOICE/ใบแจ้งหนี้</b></h1>
            <ul>
                <li>ชื่อ - นามสกุล : นายทศพร ท้าวนอก </li>
                <li>ที่อยู่ : 113 หมู่ 13 บ้านใหม่เกษมเหนือ ตำบลธารปราสาท อำเภอโนนสูง จังหวัดนครราชสีมา 30420</li>
                <li>เลขที่ประจำตัวผู้เสียภาษี : 1103300044143</li>
                <li>เบอร์โทรศัพท์มือถือ : 095-945-6626</li>
                <li>เว็บไซต์ : https://dev24.co/</li>
                <li>Line ID : @dev24</li>
            </ul>
        </div>
        <div class="col-5">
            <ul>
                <li class="color-orange">เลขที่(No.) <b>QT2023020002</b></li>
                <li class="color-orange">วันที่(Date) <b>23/02/2023</b></li>
                <li class="color-orange">ผู้ขาย(Sale) <b>จุฑาภรณ์ พึ่งพินิจ </b></li>
                <li class="color-orange">อ้างอิง (Ref1) <b>2233</b></li>
            </ul>
        </div>
    </div>
    <br>
    <h6 class="color-orange">ลูกค้า</h6>
    <ul>
        <li>บริษัท ละแมน้ำมันปาล์ม จำกัด (สำนักงานใหญ่)</li>
        <li>ที่อยู่ : 17/21 ซอยสุขุมวิท 31 (สวัสดี) แขวงคลองเตยเหนือ เขตวัฒนา กรุงเทพมหานคร 10110</li>
        <li>อีเมล : naitos1803@gmail.com / โทร. 0857711043</li>
        <li>Tax ID : 0865557000448</li>
    </ul>

    <br>
    <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">รายละเอียด</th>
            <th scope="col" class="textright">จำนวน</th>
            <th scope="col" class="textright" >ราคาต่อหน่วย</th>
            <th scope="col" class="textright" >ยอดรวม</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>ติดตั้ง Cookie เว็บไซต์ https://lamairpalmoil.com/</td>
            <td class="textright" >1</td>
            <td class="textright" >3,000.00</td>
            <td class="textright" >3,000.00</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-8"></div>
        <div class="col-4">
            <ul>
                <li>รวมเป็นเงิน <span>3,000.00 บาท</span></li>
                <li>จำนวนเงินรวมทั้งสิ้น <span>3,000.00 บาท</span></li>
            </ul>
            <hr>
            <ul>
                <li>หักภาษี ณ ที่จ่าย 3% <span>90.00 บาท</span></li>
                <li>ยอดชำระ <span>2,910.00 บาท</span></li>
            </ul>
        </div>
      </div>

      <hr>
      <div class="row"  >
            <div class="col-4" style="border-right: 1px solid #eee;" >
                <h6 class="color-orange" >เงื่อนไขการชำระเงิน</h6>
                <ul>
                    <li>งวดที่ 1) 50% ก่อนดำเนินการ</li>
                    <li>งวดที่ 2) 50% หลังส่งมอบงาน</li>
                </ul>
            </div>
            <div class="col-4" style="border-right: 1px solid #eee;">
                <h6 class="color-orange" >ข้อมูลการชำระเงิน</h6>
                <ul>
                    <li>ธนาคารไทยพาณิชย์</li>
                    <li>เลขที่บัญชี : 413-0362431</li>
                    <li>ชื่อบัญชี : นายทศพร ท้าวนอก</li>
                </ul>
            </div>
            <div class="col-4">
                <h6 class="color-orange" >หมายเหตุ</h6>
                <p>แก้ไขงานได้ไม่เกิน 2 ครั้งนับจากวันที่ส่งมอบงานแล้วเท่านั้น หากดำเนินการนอกเหนือจากนี้ ถือว่าเป็ฯโมฆะ</p>
            </div>
      </div>

      <hr>
      <div class="row">
        <div class="col-5 textcenter">
            <h6>ในนาม บริษัท ละแมน้ำมันปาล์ม จำกัด (สำนักงานใหญ่)</h6>
            <br><br>
            <div class="row">
                <div class="col-6">
                    <hr>
                    <h6>ผู้สั่งซื้อสินค้า</h6>
                </div>
                <div class="col-6">
                    <hr>
                    <h6>วันที่</h6>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5 textcenter">
            <h6>ในนาม จุฑาภรณ์ พึ่งพินิจ </h6>
            <br><br>
            <div class="row">
                <div class="col-6">
                    <hr>
                    <h6>ผู้อนุมัติ</h6>
                </div>
                <div class="col-6">
                    <hr>
                        <h6>วันที่</h6> 
                </div>
            </div>
        </div>
      </div>
</div>