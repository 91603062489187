<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext" >โดเมน/โฮสติ้ง</li>
                <li>
                    <input type="text" class="form-control" placeholder="ค้นหาข้อมูล">
                </li>
                <li>
                    <button (click)="formData = [];" onclick="$('#inSertDomain').modal('show')"
                      class="btn btn-dark text-white"><i class="bi bi-database-add"></i> เพิ่มโดเมนใหม่</button>
                </li>
            </ul>
        </div>

    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <!-- <th scope="col">รหัสโดเมน</th> -->
            <th scope="col">ชื่อโดเมน</th>
            <!-- <th scope="col" style="text-align: center;width:130px;">เริ่มจดทะเบียน</th> -->
            <th scope="col" style="text-align: center;width:130px;">ต่ออายุเมื่อ</th>
            <th scope="col" style="text-align: center;width:80px;">คงเหลือ</th>
            <!-- <th scope="col" style="text-align: center;width:80px;">โดเมน</th> -->
            <!-- <th scope="col" style="text-align: center;width:80px;">โฮสติ้ง</th> -->
            <!-- <th scope="col" style="text-align: center;background-color: #2bcbba;width:80px;">ราคารวม</th> -->
            <th scope="col" style="text-align: center;background-color: #6c5ce7;width:80px;">เรียกเก็บ</th>
            <th scope="col" style="text-align: center;background-color: #fed330;width: 200px;">ผู้ให้บริการ</th>
            <th scope="col" style="text-align: center;background-color: #d1d8e0;width:120px;">สถานะ</th>
            <th scope="col" style="text-align: center;width:40px;"></th>
            <th scope="col" style="text-align: center;width:40px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listData;let i = index;">
            <td style="width: 40px;">{{i + 1}}</td>
            <!-- <td style="width: 100px;" >{{item.code}}</td> -->
            <td>
              {{item.name}}
            </td>
            <!-- <td style="text-align: center;">{{item.start_regis}}</td> -->
            <td style="text-align: center;">{{item.exp_regis}}</td>
            <td style="text-align: center;" [ngClass]="item.expday <= 30 ? 'bg-red':'bg-green'">
              {{item.expday}} วัน</td>
            <!-- <td style="text-align: right;">{{item.price | number : '0.2-2' }}</td> -->
            <!-- <td style="text-align: right;">{{item.price_host | number : '0.2-2' }}</td> -->
            <td style="text-align: right;">{{item.totalprice | number : '0.2-2' }}</td>
            <!-- <td style="text-align: right;">{{item.pay + item.totalprice | number : '0.2-2' }}({{item.pay}})</td> -->
            <td style="text-align: center;">{{item.service}}</td>
            <td style="text-align: center;">
              <span *ngIf="item.status == 1" style="color: green;">ปกติ</span>
              <span *ngIf="item.status == 2" style="color: orangered;">หมดอายุ</span>
              <span *ngIf="item.status == 3" style="color: red;">ยกเลิก</span>
            </td>
            <td (click)="formData = item;editSelect = 1;"
              style="text-align: center;">
              <i class="bi bi-pencil"></i>
            </td>
            <td (click)="removeData(item.id)" style="text-align: center;">
              <i style="color: #eb3b5a;" class="bi bi-trash"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-end" style="margin: 0px !important;">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1">ก่อนหน้า</a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">ถัดไป</a>
                      </li>
                    </ul>
                  </nav>
            </div>
  </div>


  <!-- Modal -->
  <div class="modal fade" id="inSertDomain" tabindex="-1" role="dialog" aria-labelledby="inSertDomainTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-secondary">
          <h5 class="modal-title text-white" id="exampleModalLongTitle">เพิ่มข้อมูลโดเมน</h5>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">ชื่อโปรเจ็ค</label>
                  <input [(ngModel)]="formData.name" type="text" class="form-control">
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">สถานะ</label>
                  <select [(ngModel)]="formData.status" class="custom-select form-control">
                    <option selected>เลือก...</option>
                    <option value="1">ปกติ</option>
                    <option value="2">หมดอายุ</option>
                    <option value="3">ยกเลิก</option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">ราคาโดเมน</label>
                  <input [(ngModel)]="formData.price" type="number" class="form-control">
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">ราคาโฮสติ้ง</label>
                  <input [(ngModel)]="formData.price_host" type="number" class="form-control">
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">เรียกเก็บเงิน</label>
                  <input [(ngModel)]="formData.totalprice" type="number" class="form-control">
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">เริ่มจดทะเบียน</label>
                  <input [(ngModel)]="formData.start_regis" type="date" class="form-control">
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <label class="form-label">ต่ออายุเมื่อ</label>
                  <input [(ngModel)]="formData.exp_regis" type="date" class="form-control">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">ลูกค้า</label>
                  <select [(ngModel)]="formData.customer_id" class="custom-select form-control">
                    <option selected>Choose...</option>
                    <option *ngFor="let item of listCustomer;" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">ผู้ให้บริการ</label>
                  <input type="text" [(ngModel)]="formData.service" class="form-control">
                </div>
              </div>
            </div>
          </div>

          <!-- add one close -->
          <div style="text-align: right;">
            <button onclick="$('#inSertDomain').modal('hide')" type="button"
              style="width:100px;margin-right: 10px;height: 45px;" class="btn btn-secondary" data-bs-dismiss="modal"><i
                class="fas fa-times"></i> ปิด</button>
            <button *ngIf="editSelect == 0" (click)="insertData()" type="button" style="width:180px;height: 45px;"
              class="btn btn-success"><i class="fas fa-plus"></i> เพิ่มรายการใหม่</button>
            <button *ngIf="editSelect == 1" (click)="updateData()" type="button"
              style="width:180px;height: 45px;background-color: #0984e3;color: #fff;" class="btn"><i
                class="fas fa-check"></i> อัพเดทข้อมูล</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</app-maincontent>