<div class="print" >
    <div class="row">
        <div class="col-7">
            <ul>
                <li>ชื่อ - นามสกุล : จุฑาภรณ์ พึ่งพินิจ </li>
                <li>ที่อยู่ : 118 หมู่ 3 ตำบลดงละคร อำเภอเมือง จังหวัดนครนายก 26000</li>
                <li>เลขที่ประจำตัวผู้เสียภาษี : 1103300044143</li>
                <li>เบอร์โทรศัพท์มือถือ : 095-945-6626</li>
                <li>โทรสาร : </li>
                <li>เว็บไซต์ : https://dev24.co/</li>
            </ul>
        </div>
        <div class="col-5">
            <h1 class="textcenter" >ใบเสนอราคา</h1>
            <ul>
                <li class="color-orange">เลขที่ <b>QT2023020002</b></li>
                <li class="color-orange">วันที่ <b>23/02/2023</b></li>
                <li class="color-orange">ผู้ขาย <b>จุฑาภรณ์ พึ่งพินิจ </b></li>
            </ul>
            <hr>
            <ul>
                <li class="color-orange">ผู้ติดต่อ <b>บริษัท ละแมน้ำมันปาล์ม จำกัด (สำนักงานใหญ่)</b></li>
                <li class="color-orange">เบอร์โทรศัพท์ <b>-</b></li>
            </ul>
        </div>
    </div>
    <br>
    <h6 class="color-orange">ลูกค้า</h6>
    <!-- <ul>
        <li>บจก. เซอร์คูล่า เอ็นเนอร์ยี</li>
        <li>ที่อยู่ :100/17 หมู่ที่ 19 ตำบล คลองหนึ่ง อำเภอคลองหลวง จ.ปทุมธานี</li>
        <li>อีเมล : cecircular.energy@gmail.com</li>
        <li>Tax ID :0735563000978</li>
    </ul> -->
    <ul>
        <li>บริษัท ละแมน้ำมันปาล์ม จำกัด (สำนักงานใหญ่)</li>
        <li>ที่อยู่ : 17/21 ซอยสุขุมวิท 31 (สวัสดี) แขวงคลองเตยเหนือ เขตวัฒนา กรุงเทพมหานคร 10110</li>
        <li>อีเมล : -</li>
        <li>Tax ID : 0865557000448</li>
    </ul>

    <br><br>
    <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">รายละเอียด</th>
            <th scope="col" class="textright">จำนวน</th>
            <th scope="col" class="textright" >ราคาต่อหน่วย</th>
            <th scope="col" class="textright" >ยอดรวม</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>ติดตั้ง Cookie เว็บไซต์ https://lamairpalmoil.com/</td>
            <td class="textright" >1</td>
            <td class="textright" >3,000.00</td>
            <td class="textright" >3,000.00</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-8"></div>
        <div class="col-4">
            <ul>
                <li>รวมเป็นเงิน <span>3,000.00 บาท</span></li>
                <li>จำนวนเงินรวมทั้งสิ้น <span>3,000.00 บาท</span></li>
            </ul>
            <hr>
            <ul>
                <li>หักภาษี ณ ที่จ่าย 3% <span>90.00 บาท</span></li>
                <li>ยอดชำระ <span>2,910.00 บาท</span></li>
            </ul>
        </div>
      </div>

      <div>
            <h6 class="color-orange" >หมายเหตุ</h6>
            <p style="white-space: pre-wrap;" >
                เงื่อนไขการชำาระเงิน : <br>
                    งวดที่ 1) 100% ก่อนดำเนินการ
               <br><br>
                ย้อมูลการชำระเงิน :  <br>
                ธนาคารกรุงศรี <br> 619-1-16657-9 <br> จุฑาภรณ์ พึ่งพินิจ
            </p >
      </div>

      <br><br>
      <div class="row">
        <div class="col-5 textcenter">
            <h6>ในนาม บริษัท ละแมน้ำมันปาล์ม จำกัด (สำนักงานใหญ่)</h6>
            <br><br>
            <div class="row">
                <div class="col-6">
                    <hr>
                    <h6>ผู้สั่งซื้อสินค้า</h6>
                </div>
                <div class="col-6">
                    <hr>
                    <h6>วันที่</h6>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5 textcenter">
            <h6>ในนาม จุฑาภรณ์ พึ่งพินิจ </h6>
            <br><br>
            <div class="row">
                <div class="col-6">
                    <hr>
                    <h6>ผู้อนุมัติ</h6>
                </div>
                <div class="col-6">
                    <hr>
                        <h6>วันที่</h6> 
                </div>
            </div>
        </div>
      </div>
</div>