<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext">สรุปแผนการจัดการรวม</li>
            </ul>
        </div>
        <div class="card-body">
            <!-- <div class="card">
                        <h6 style="margin: 0px;border-bottom: 1px solid #eee;padding-bottom: 10px;">ภาพรวมงานทั้งหมด
                        </h6>

                        <div class="row">
                            <div class="col-2 text-center" style="padding-right:0px;">
                                <div
                                    style="background-color: #0fb9b1;padding: 10px;border-radius: 15px;margin-top: 10px;">
                                    <h2 style="margin: 0px;color: #ffffff;"><b>{{websiteGroup.running}}</b></h2>
                                    <h6 style="margin: 2px;color: #ffffff;font-weight: 300;font-size: 14px;">Webiste
                                    </h6>
                                </div>
                                <h6 style="margin-top: 10px;font-size: 14px;color: #999999;">งานทั้งหมด
                                    {{websiteGroup.total}} งาน</h6>
                                <h6 style="margin-top: -7px;font-size: 14px;color: #999999;">สำเร็จ
                                    {{websiteGroup.success}}
                                    งาน</h6>
                            </div>
                            <div class="col-2 text-center" style="padding-right:0px;">
                                <div
                                    style="background-color: #20bf6b;padding: 10px;border-radius: 15px;margin-top: 10px;">
                                    <h2 style="margin: 0px;color: #ffffff;"><b>{{appGroup.running}}</b></h2>
                                    <h6 style="margin: 2px;color: #ffffff;font-weight: 300;font-size: 14px;">Application
                                    </h6>
                                </div>
                                <h6 style="margin-top: 10px;font-size: 14px;color: #999999;">งานทั้งหมด
                                    {{appGroup.total}}
                                    งาน</h6>
                                <h6 style="margin-top: -7px;font-size: 14px;color: #999999;">สำเร็จ {{appGroup.success}}
                                    งาน
                                </h6>
                            </div>
                            <div class="col-2 text-center" style="padding-right:0px;">
                                <div
                                    style="background-color: #f7b731;padding: 10px;border-radius: 15px;margin-top: 10px;">
                                    <h2 style="margin: 0px;color: #ffffff;"><b>{{supportGroup.running}}</b></h2>
                                    <h6 style="margin: 2px;color: #ffffff;font-weight: 300;font-size: 14px;">Support
                                    </h6>
                                </div>
                                <h6 style="margin-top: 10px;font-size: 14px;color: #999999;">ทั้งหมด
                                    {{supportGroup.total}}
                                    งาน</h6>
                                <h6 style="margin-top: -7px;font-size: 14px;color: #999999;">สำเร็จ
                                    {{supportGroup.success}}
                                    งาน</h6>
                            </div>
                            <div class="col-6 text-center">
                            </div>
                        </div>
                    </div> -->


                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">ปี</th>
                            <th scope="col">ลูกค้าใหม่</th>
                            <th scope="col">โดเมน/โฮสติ้ง</th>
                            <th scope="col">โปรเจ็ค</th>
                            <th style="text-align: right;" scope="col">รายได้รวม</th>
                            <th style="text-align: right;" scope="col">ได้รับชำระแล้ว</th>
                            <th style="text-align: right;" scope="col">ค้างชำระ</th>
                            <th style="text-align: center;" scope="col">เอกสารภาษี</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of myApp;">
                            <td>{{item.year}}</td>
                            <td>99 คน</td>
                            <td>99 เว็บ</td>
                            <td>99 รายการ</td>
                            <td style="text-align: right;" >{{item.totalsum | number :'0.2-2'}}</td>
                            <td style="text-align: right;" >{{item.totalpay | number :'0.2-2'}}</td>
                            <td style="text-align: right;" >{{item.totalsum - item.totalpay | number :'0.2-2'}}</td>
                            <td style="text-align: center;" >
                                <i class="bi bi-file-arrow-down"></i>ดาว์นโหลด
                            </td>
                            <td style="text-align: right;" >
                                <button class="btn btn-dark" ><i class="bi bi-search"></i> ตรวจสอบ</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
</app-maincontent>