<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext" >ผู้ดูแลระบบ</li>
                <li>
                    <input type="text" placeholder="ค้นหาข้อมูล" class="form-control">
                </li>
                <li>
                    <button class="btn btn-success" ><i class="bi bi-file-earmark-excel"></i> ดาว์นโหลด</button>
                </li>
                <li>
                    <button class="btn btn-dark text-white" ><i class="bi bi-person-plus"></i> เพิ่มบัญชีใหม่</button>
                </li>
            </ul>
        </div>
        
        <div class="card-body">
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">รหัสลูกค้า</th>
                    <th scope="col">ชื่อลูกค้า</th>
                    <th scope="col">ชื่อบริษัท</th>
                    <!-- <th scope="col" >ที่อยู่</th> -->
                    <th scope="col" style="width: 150px;" >เลขที่บัญชีผู้เสียภาษี</th>
                    <th scope="col" style="width: 150px;" >หมายเลขโทรศัพท์</th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 40px;" >1</td>
                    <td style="width: 100px;" >CF002523</td>
                    <td>ทศพร ท้าวนอก</td>
                    <td style="text-align: center;" >บริษัท นายดีไซน์ จำกัด</td>
                    <!-- <td style="text-align: center;" >50/32 หมู่บ้านกมลลักษณ์ ถนนรัตนาธิเบศ์ บางรักใหญ่ บางบัวทอง นนทบุรี 11110</td> -->
                    <td style="text-align: center;" >1309901205846</td>
                    <td style="text-align: center;" >0857711043</td>
                    <td style="text-align: center;">
                        <i class="bi bi-pencil"></i>
                    </td>
                    <td style="text-align: center;">
                        <i style="color: #eb3b5a;" class="bi bi-trash"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="card-footer">
            <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end" style="margin: 0px !important;">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">ก่อนหน้า</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#">ถัดไป</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>


  <!-- Modal -->
  <div class="modal fade" id="addAdmin" tabindex="-1" role="dialog" aria-labelledby="addAdminTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button onclick="$('#addAdmin').modal('hide')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button onclick="$('#addAdmin').modal('hide')" type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</app-maincontent>