<app-maincontent>
    <div class="card">
        <div class="card-header">
            <ul>
                <li class="titletext" >ตั้งค่าระบบ</li>
                <li>
                    <button class="btn btn-primary" ><i class="bi bi-floppy"></i> บันทึกข้อมูล</button>
                </li>
            </ul>
        </div>
        <div style="border-bottom: 1px solid #eee;" > 
            <ul class="listdetail" >
                <li class="active" >ข้อมูลบริษัท/ส่วนบุคคล</li>
                <li>รหัสเอกสาร</li>
                <li>บัญชีธนาคาร</li>
            </ul>
        </div>
        <div class="card-body">

        </div>
    </div>
</app-maincontent>