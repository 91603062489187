


<div class="card-loign">
        <div class="form-loign" style="border-radius: 0 0 15px 15px;">
        <img style="width: 100px;margin: 0 auto;display: block;margin-bottom: 15px;" src="assets/icon/briefcase.png" alt="">
        <h3 style="text-align: center;"><b>เข้าสู่ระบบบัญชีแอดมิน</b></h3>
        <p style="text-align: center;" >การบริหารการจัดการระบบรับงาน <br> และประเมินการทำงานของระบบ</p>
            <div>
                <div class="form-group">
                  <label>อีเมล(Email)</label>
                  <input [(ngModel)]="formData.email" type="email" class="form-control">
                </div>
                <div class="form-group">
                  <label>รหัสผ่าน(Password)</label>
                  <input [(ngModel)]="formData.password" type="password" class="form-control">
                </div>
                <button (click)="login()" type="submit" class="btn btn-primary"><b>เข้าสู่ระบบ</b></button>
                <h6 class="text-center mt-10" >Version 1.0.0</h6>
            </div>
        </div>
</div>