import { Component } from '@angular/core';

@Component({
  selector: 'app-quotationprint',
  templateUrl: './quotationprint.component.html',
  styleUrls: ['./quotationprint.component.scss']
})
export class QuotationprintComponent {

}
