<app-maincontent>
    <div>
        <div class="card">
            <div class="card-header">
                <ul>
                    <li class="titletext" >รายจ่าย/ซื้อสินค้า</li>
                    <li>
                        <select class="form-select" >
                            <option selected>ทั้งหมด</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                    </li>
                    <li>
                        <input type="text" class="form-control" placeholder="ค้นหาข้อมูล">
                    </li>
                    <li>
                        <button class="btn btn-primary"  ><i class="bi bi-file-plus"></i> สร้างใบเสนอราคา</button>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">รหัสโปรเจ็ค</th>
                        <th scope="col">รหัสเอกสาร</th>
                        <th scope="col">ลูกค้า</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ราคาเต็ม</th>
                        <th scope="col">หัก ณ ที่จ่าย</th>
                        <th scope="col">สถานะ</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>P1195</td>
                        <td>Q20240106</td>
                        <td>บริษัท เบสิกคิว จำกัด</td>
                        <td>
                            <span class="badge rounded-pill bg-warning text-dark">แบ่งชำระ</span>
                        </td>
                        <td>10,000.-</td>
                        <td>3% (300.-)</td>
                        <td>อนุมัติแล้ว</td>
                        <td style="text-align: right;" >
                            <i style="font-size: 18px;"  class="bi bi-pencil"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-trash"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-printer"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-three-dots-vertical"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>P1195</td>
                        <td>Q20240106</td>
                        <td>บริษัท เบสิกคิว จำกัด</td>
                        <td>
                            <span class="badge rounded-pill bg-warning text-dark">แบ่งชำระ</span>
                        </td>
                        <td>10,000.-</td>
                        <td>3% (300.-)</td>
                        <td>อนุมัติแล้ว</td>
                        <td style="text-align: right;" >
                            <i style="font-size: 18px;"  class="bi bi-pencil"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-trash"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-printer"></i>
                            <i style="font-size: 18px;margin-left: 25px;"  class="bi bi-three-dots-vertical"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            <div class="card-footer">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-end" style="margin: 0px !important;">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1">ก่อนหน้า</a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">ถัดไป</a>
                      </li>
                    </ul>
                  </nav>
            </div>
        </div>
    </div>
</app-maincontent>