import { Component } from '@angular/core';

@Component({
  selector: 'app-invoiceprint',
  templateUrl: './invoiceprint.component.html',
  styleUrls: ['./invoiceprint.component.scss']
})
export class InvoiceprintComponent {

}
