import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { CustomerComponent } from './customer/customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DomainComponent } from './domain/domain.component';
import { GuaranteeComponent } from './guarantee/guarantee.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { LoginComponent } from './login/login.component';
import { MettingComponent } from './metting/metting.component';
import { ProjectComponent } from './project/project.component';
import { InvoiceprintComponent } from './invoiceprint/invoiceprint.component';
import { QuotationComponent } from './quotation/quotation.component';
import { QuotationprintComponent } from './quotationprint/quotationprint.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SettingComponent } from './setting/setting.component';

const routes: Routes = [
   { path : '' , pathMatch : 'full' , redirectTo : '/dashboard'},
   { path : 'dashboard', component : DashboardComponent },
   { path : 'project' , component : ProjectComponent},
   { path : 'domain' , component : DomainComponent},
   { path : 'login' , component : LoginComponent},
   { path : 'admin' , component : AdminComponent},
   { path : 'customer' , component : CustomerComponent},
   { path : 'guarantee' , component : GuaranteeComponent},
   { path : 'metting' , component : MettingComponent},
   { path : 'invoice' , component : InvoiceComponent},
   { path : 'invoice/:id' , component : InvoiceprintComponent},
   { path : 'quotation' , component : QuotationComponent},
   { path : 'quotation/:id' , component : QuotationprintComponent},
   { path : 'expenses' , component : ExpensesComponent},
   { path : 'setting' , component : SettingComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
