import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CenterService } from '../center.service';
declare var $;

@Component({
  selector: 'app-maincontent',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.component.scss']
})
export class MaincontentComponent implements OnInit {


  openSlide = true;
  profileShow = false;
  showEditpass = false;
  showTotolist = false;

  formData: any = [];
  formDataFile: FormData = new FormData();
  switchSmall = 0;
  constructor(
      private router: Router,
      public center: CenterService,
      private http : HttpClient,
      // private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
      this.center.checkLogin();
  //    setTimeout(() => {
  //     var path = window.location.pathname;
  //     console.log(path);
  //     if (this.center.myData.position == 3) {
  //         console.log(path);
  //         if (path == '/myplan' || path == '/dashboard') {
  //             this.router.navigateByUrl('plan');
  //         }else if (path == '/') {
  //             this.router.navigateByUrl('plan');
  //         }
  //     } else if(this.center.myData.position == 2) {
  //         if (path == '/student' || path == '/myplan' || path == '/plan' || path == '/teacher') {
  //             this.router.navigateByUrl('dashboard');
  //         }else if (path == '/') {
  //             this.router.navigateByUrl('dashboard');
  //         }
  //     } else if (this.center.myData.position == 4){
  //         if (path == '/dashboard' || path == '/plan' || path == '/teacher') {
  //             this.router.navigateByUrl('myplan');
  //         }else if (path == '/') {
  //             this.router.navigateByUrl('myplan');
  //         }
  //     }
  //    }, 500);
  }

  switchSlid() {
      // console.log("on/off");
      if (this.openSlide == true) {
          this.openSlide = false;
          // console.log($('.maintent'));
          $('.maintent').addClass("maintent-full");
          $('.slidbar').addClass("slidbar-mini");

      } else {
          this.openSlide = true;
          $('.maintent').removeClass("maintent-full");
          $('.slidbar').removeClass("slidbar-mini");
      }
  }

  logout() {
      localStorage.removeItem('uuid');
      this.router.navigateByUrl('/login');
  }
}
