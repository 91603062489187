import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-guarantee',
  templateUrl: './guarantee.component.html',
  styleUrls: ['./guarantee.component.scss']
})
export class GuaranteeComponent implements OnInit {

  listData : any = [];
  listCustomer : any = [];
  formData : any = [];
  editSelect = 0;
  constructor(
      private http: HttpClient
  ) { }

  ngOnInit(): void {
      this.getData();
      this.getCustomer();
  }

  insertData(){
      this.editSelect = 0;
      var dataset =    
      {
          "action": "INNERT_DATA",
          "body": {
              "name" : this.formData.name,
              "exp" : this.formData.exp,
              "status" : this.formData.status,
              "username" :this.formData.username,
              "email" : this.formData.email,
              "password" : this.formData.password,
              "customer_id" : this.formData.customer_id
          }
      }

      this.http.post(environment.baseURL + '/guarantee.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          $('#inSertDomain').modal('hide');
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

  getCustomer(){
      var dataset =  {
        "action": "GET_DATA"
      }
  
      this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
        console.log(res);
        this.listCustomer = res['result'];
      },err => {
        console.log(err);
      });
    }

  getData() {
      var dataset = {
          "action": "GET_DATA"
      }

      this.http.post(environment.baseURL + '/guarantee.php', JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.listData = Object.keys(res['result']).map(key => res['result'][key]);
      }, err => {
          console.log(err);
      });
  }

  updateData(){
      var dataset =  {
          "action": "UPDATE_DATA",
          "body": {
            "name" : this.formData.name,
            "exp" : this.formData.exp,
            "status" : this.formData.status,
            "username" :this.formData.username,
            "email" : this.formData.email,
            "password" : this.formData.password,
            "customer_id" : this.formData.customer_id
          },
          "condition": {
              "record_id": this.formData.id
          }
      }

      this.http.post(environment.baseURL + '/guarantee.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          $('#inSertDomain').modal('hide');
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

  generateKey(){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 25; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.formData.password = result;
  }

  removeData(id){
      var dataset =   {
          "action": "DELETE_DATA",
          "condition": {
              "record_id": id
          }
      }

      this.http.post(environment.baseURL + '/guarantee.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

}
