import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  listData : any = [];
  listCustomer : any = [];
  formData : any = [];
  editSelect = 0;
  constructor(
      private http: HttpClient
  ) { }

  ngOnInit(): void {
      this.getData();
  }

  insertData(){
      this.editSelect = 0;
      var dataset =  {
        "action": "INNERT_DATA",
        "body": {
            "name" : this.formData.name,
            "phone" : this.formData.phone,
            "code" : this.formData.code,
            "company" : this.formData.company,
            "tax" : this.formData.tax,
            "email" : this.formData.email,
            "address" : this.formData.address
        }
      }

      this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          $('#inSertDomain').modal('hide');
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

  getData() {
      var dataset = {
          "action": "GET_DATA"
      }

      this.http.post(environment.baseURL + '/customer.php', JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.listData = Object.keys(res['result']).map(key => res['result'][key]);
      }, err => {
          console.log(err);
      });
  }

  updateData(){
      var dataset =  {
          "action": "UPDATE_DATA",
          "body": {
            "name" : this.formData.name,
            "phone" : this.formData.phone,
            "code" : this.formData.code,
            "company" : this.formData.company,
            "tax" : this.formData.tax,
            "email" : this.formData.email,
            "address" : this.formData.address
          },
          "condition": {
              "record_id": this.formData.id
          }
      }

      this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          $('#inSertDomain').modal('hide');
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

  removeData(id){
      var dataset =   {
          "action": "DELETE_DATA",
          "condition": {
              "record_id": id
          }
      }

      this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.formData = [];
          this.getData();
        },err => {
          console.log(err);
      });
  }

}
