import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CenterService {
    myData: any = [];
    myuuid;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {

    }

    checkLogin() {
        this.myuuid = localStorage.getItem('uuid');
        if (this.myuuid == null || this.myData == undefined) {
            this.router.navigateByUrl('/login');
        } else {

            // get admin
            var dataset = {
                "action": "GET_DATA",
                "body": {
                    "uuid": this.myuuid
                }
            }

            this.http.post(environment.baseURL + '/auth.php', JSON.stringify(dataset)).subscribe(res => {
                console.log(res);
                this.myData = res['result'];
            }, err => {
                console.log(err)
            });
        }
    }
}
