<app-maincontent>
    <br><br><br>
    <div class="container">
        <h5>โดเมน/โฮสติ้ง</h5>
        <button onclick="$('#inSertDomain').modal('show')" style="float: right;margin-top: -40px;margin-right:230px;" class="btn btn-dark text-white" ><i class="fas fa-plus"></i> เพิ่มรายการใหม่</button>
        <div style="width: 220px;float: right;margin-top: -40px;" class="form-group">
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <div class="input-group-text" style="border-radius: .25rem 0 0 .25rem;">เลือกปีเเสดงผล</div>
                </div>
                <select class="form-control" id="exampleFormControlSelect1" style="text-align: center;">
                    <option>2563</option>
                    <option>2564</option>
                    <option>2565</option>
                </select>
            </div>
        </div>


        <div class="card" style="margin-top: 20px;" >
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"  style="width: 300px;" >ชื่องาน</th>
                    <th scope="col" style="text-align: center;width:200px;" >วันที่ประชุม</th>
                    <th scope="col" style="text-align: center;width:200px;" >ผู้ติดต่อ</th>
                    <th scope="col" style="text-align: center;" >ที่อยู่</th>
                    <th scope="col" style="text-align: center;background-color: #d1d8e0;width:120px;" >สถานะ</th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                    <th scope="col" style="text-align: center;width:40px;" ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listData;let i = index;" >
                    <td style="width: 40px;" >{{i + 1}}</td>
                    <td style="text-align: center;" >{{item.name}}</td>
                    <td style="text-align: center;" >{{item.datestart}}</td>
                    <td style="text-align: center;" >{{item.user}} / {{item.phone}}</td>
                    <td style="text-align: center;" >{{item.location}}</td>
                    <td style="text-align: center;background-color: #20bf6b;" >
                        <span *ngIf="item.status == 1" style="color: #fff;" >รอประชุม</span>
                        <span *ngIf="item.status == 2" style="color: #fff;" >ประชุมแล้ว</span>
                        <span *ngIf="item.status == 3" style="color: #fff;" >ยกเลิก</span>
                    </td>
                    <td (click)="formData = item;editSelect = 1;" onclick="$('#inSertDomain').modal('show')" style="text-align: center;">
                        <i class="bi bi-pencil"></i>
                    </td>
                    <td (click)="removeData(item.id)" style="text-align: center;">
                        <i style="color: #eb3b5a;" class="bi bi-trash"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>


      <!-- Modal -->
  <div class="modal fade" id="inSertDomain" tabindex="-1" role="dialog" aria-labelledby="inSertDomainTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-secondary">
          <h5 class="modal-title text-white" id="exampleModalLongTitle">เพิ่มข้อมูลโดเมน</h5>
        </div>
        <div class="modal-body">
            <div>
                <div class="row">
                    <div class="col-8">
                        <div class="mb-3">
                          <label class="form-label">ชื่องาน</label>
                          <input [(ngModel)]="formData.name" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label class="form-label">วันที่ประชุม</label>
                          <input [(ngModel)]="formData.datestart" type="date" class="form-control">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mb-3">
                          <label class="form-label">สถานะ</label>
                          <select [(ngModel)]="formData.status" class="custom-select form-control">
                            <option selected>เลือก...</option>
                            <option value="1">รอประชุม</option>
                            <option value="2">ประชุมแล้ว</option>
                            <option value="3">ยกเลิก</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3">
                          <label class="form-label">ชื่อผู้ติดต่อ</label>
                          <input [(ngModel)]="formData.user" type="text" class="form-control">
                        </div>
                  </div>
                    <div class="col-4">
                        <div class="mb-3">
                            <label class="form-label">หมายเลขโทรศัพท์</label>
                            <input [(ngModel)]="formData.phone" type="text" class="form-control">
                          </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">ที่อยู่</label>
                            <textarea [(ngModel)]="formData.location" type="text" rows="3" class="form-control"></textarea>
                          </div>
                    </div>
                </div>
            </div >

            <!-- add one close -->
            <div style="text-align: right;" >
                <button onclick="$('#inSertDomain').modal('hide')" type="button" style="width:100px;margin-right: 10px;height: 45px;" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times"></i> ปิด</button>
                <button *ngIf="editSelect == 0" (click)="insertData()" type="button" style="width:180px;height: 45px;"  class="btn btn-success"><i class="fas fa-plus"></i> เพิ่มรายการใหม่</button>
                <button *ngIf="editSelect == 1" (click)="updateData()" type="button" style="width:180px;height: 45px;background-color: #0984e3;color: #fff;"  class="btn"><i class="fas fa-check"></i> อัพเดทข้อมูล</button>
            </div>
        </div>
      </div>
    </div>
  </div>

</app-maincontent>