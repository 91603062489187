<div class="container-fluid">
    <div class="row">
        <div class="slidbar slidbar-mini-size" [ngClass]="{'slidbar-mini' : switchSmall == 1}">
            <div class="headerset" style="background-color: #06163a;padding-bottom: 25px;border-bottom: 1px solid #ffffff21;">
                <img class="logo" style="width: 40%;margin:  auto;display: block;" src="assets/icon/briefcase.png"
                    alt="">
                <h5 class="company is-desktop" style="margin-bottom: 5px;margin: 0px;margin-top: 6px;">
                    ระบบจัดการงาน <br>
                    <span style="font-size: 14px;">เวอร์ชั่น 1.0.0</span>
                </h5>
            </div>
            <ul>

                <!-- customer -->
                <li class="header-slid">จัดการโปรเจ็ค</li>
                <li routerLink="/dashboard" routerLinkActive="active-nav"><i class="bi bi-clipboard-data"></i> <span>ภาพรวมแผน</span></li>
                <li routerLink="/project" routerLinkActive="active-nav"><i class="bi bi-briefcase"></i> <span>โปรเจ็ค</span></li>
                <li routerLink="/domain" routerLinkActive="active-nav"><i class="bi bi-database"></i> <span>โดเมน/โฮสติ้ง</span></li>
                <li routerLink="/guarantee" routerLinkActive="active-nav"><i class="bi bi-shield-check"></i> <span>การรับประกัน</span></li>


                <!-- money -->
                <li class="header-slid">การเงิน/บัญชี</li>
                <li routerLink="/quotation" routerLinkActive="active-nav"><i class="bi bi-receipt"></i> <span>ใบเสนอราคา</span></li>
                <li routerLink="/invoice" routerLinkActive="active-nav"><i class="bi bi-file-earmark-medical"></i> <span> ใบแจ้งหนี้/ใบเสร็จรับเงิน</span></li>
                <li routerLink="/expenses" routerLinkActive="active-nav"><i class="bi bi-bag"></i> <span>รายจ่าย/ซื้อสินค้า</span></li>

                <!-- member -->
                <li class="header-slid">จัดการผู้ใช้งาน</li>
                <li routerLink="/customer" routerLinkActive="active-nav"><i class="bi bi-people"></i> <span>สมาชิก/ลูกค้า</span></li>
                <li routerLink="/admin" routerLinkActive="active-nav"><i class="bi bi-person"></i> <span> ผู้ดูแลระบบ</span></li>
                <li routerLink="/setting" routerLinkActive="active-nav"><i class="bi bi-gear"></i> <span> ตั้งค่าระบบ</span></li>

                <li (click)="logout()" style="background-color: #dc3545;color: #ffffff;width: 100%;"><i class="bi bi-x-lg"></i>
                    <span> ออกจากระบบ</span>
                </li>
            </ul>
        </div>

        <div class="maintent maintent-full-size" [ngClass]="{'maintent-full-size-active' : switchSmall == 1}">
            <div class="top-header">
                <div class="top-search">
                    <i *ngIf="switchSmall == 1" (click)="switchSmall = 0;switchSlid()" class="bi bi-arrow-right"></i>
                    <i *ngIf="switchSmall == 0" (click)="switchSmall = 1;switchSlid()" class="bi bi-arrow-left"></i>
                    <h6 style="margin: 8px;color: #fff;">ระบบจัดการงาน Console Report</h6>
                </div>
                <div class="top-right">
                    <button class="iconshow btn btn-light" style="color: #fff;">ทศพร[1000]</button>
                    <button class="iconshow btn text-white" style="border-left: 1px solid #eeeeee40;border-radius: 0px;padding-left: 15px;">
                        <i class="bi bi-bell"></i>
                    </button>
                    <button class="iconshow btn text-white" style="border-radius: 0px;">
                        <i class="bi bi-info-circle"></i>
                    </button>
                    <button class="iconshow btn text-white" style="border-radius: 0px;">
                        <!-- <i class="bi bi-fullscreen"></i> -->
                        <i class="bi bi-fullscreen-exit"></i>
                    </button>
                    <button (click)="logout()" class="btn text-white" style="border-left: 1px solid #eeeeee40;border-radius: 0px;padding-left: 20px;" >ออกจากระบบ</button>
                </div>
            </div>

            <div style="padding: 30px;padding-bottom: 0px !important;padding-top: 100px;">
                <div>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>