import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
    selector: 'app-domain',
    templateUrl: './domain.component.html',
    styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements OnInit {

    listData : any = [];
    listCustomer : any = [];
    formData : any = [];
    editSelect = 0;
    constructor(
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.getData();
        this.getCustomer();
    }

    insertData(){
        this.editSelect = 0;
        var dataset =    {
            "action": "INNERT_DATA",
            "body": {
                "name" : this.formData.name,
                "start_regis" : this.formData.start_regis,
                "exp_regis" : this.formData.exp_regis,
                "price" : this.formData.price,
                "price_host" : this.formData.price_host,
                "service" : this.formData.service,
                "status" : this.formData.status,
                "customer_id" : this.formData.customer_id
            }
        }

        this.http.post(environment.baseURL + '/domain.php',JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            $('#inSertDomain').modal('hide');
            this.formData = [];
            this.getData();
          },err => {
            console.log(err);
        });
    }

    getCustomer(){
        var dataset =  {
          "action": "GET_DATA"
        }
    
        this.http.post(environment.baseURL + '/customer.php',JSON.stringify(dataset)).subscribe(res => {
          console.log(res);
          this.listCustomer = res['result'];
        },err => {
          console.log(err);
        });
      }

    getData() {
        var dataset = {
            "action": "GET_DATA"
        }

        this.http.post(environment.baseURL + '/domain.php', JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.listData = Object.keys(res['result']).map(key => res['result'][key]);
        }, err => {
            console.log(err);
        });
    }

    updateData(){
        var dataset =  {
            "action": "UPDATE_DATA",
            "body": {
                "name" : this.formData.name,
                "start_regis" : this.formData.start_regis,
                "exp_regis" : this.formData.exp_regis,
                "price" : this.formData.price,
                "totalprice" : this.formData.totalprice,
                "price_host" : this.formData.price_host,
                "service" : this.formData.service,
                "status" : this.formData.status,
                "customer_id" : this.formData.customer_id
            },
            "condition": {
                "record_id": this.formData.id
            }
        }

        this.http.post(environment.baseURL + '/domain.php',JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            $('#inSertDomain').modal('hide');
            this.formData = [];
            this.getData();
          },err => {
            console.log(err);
        });
    }

    removeData(id){
        var dataset =   {
            "action": "DELETE_DATA",
            "condition": {
                "record_id": id
            }
        }

        this.http.post(environment.baseURL + '/domain.php',JSON.stringify(dataset)).subscribe(res => {
            console.log(res);
            this.formData = [];
            this.getData();
          },err => {
            console.log(err);
        });
    }

}
